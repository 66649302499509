@import '../../config.scss';

.base {
    @include center;
    border-radius: 16px;
    color: #ffffff;
    display: inline-flex;
    height: rem(24);
    font-size: rem(14);
    line-height: rem(14);
    @include text(400);
    min-width: 112px;
    padding: 1px rem(12) 3px rem(12);
    text-transform: lowercase;

    &.error {
        background-color: $color-error;
    }

    &.success {
        background-color: $color-success;
    }

    &.warning {
        background-color: $color-warning;
    }

    &.grey {
        background-color: $color-grey-500;
    }

    &.secondary {
        background-color: $color-secondary-500;
    }

    &.primary {
        background-color: $color-primary-500;
    }

    &.border {
        &.error {
            background-color: transparent;
            border: solid 1px $color-error;
            color: $color-error;
        }
    
        &.success {
            background-color: transparent;
            border: solid 1px $color-success;
            color: $color-success;
        }
    
        &.warning {
            background-color: transparent;
            border: solid 1px $color-warning;
            color: $color-warning;
        }

        &.grey {
            background-color: transparent;
            border: solid 1px $color-grey-500;
            color: $color-grey-500;
        }

        &.secondary {
            background-color: transparent;
            border: solid 1px $color-secondary-500;
            color: $color-secondary-500;
        }

        &.primary {
            background-color: transparent;
            border: solid 1px $color-primary-500;
            color: $color-primary-500;
        }
    }
}
