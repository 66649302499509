@import '../../../kit/config';

.base {
    @include center-y;

    > a, > button {
        margin-left: rem(16);
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.breadcrumb {
    @include center-y;
    border-right: solid 1px $color-grey-200;
    justify-content: space-between;
    padding: 0 rem(64) 0 rem(76);
    height: rem(60);

    .arrow {
        cursor: pointer;
        position: relative;

        svg {
            color: $color-primary-500;
            font-size: rem(24);
            margin-right: rem(16);
            position: relative;
            transform: translateX(0);
            transition: transform 150ms linear;
        }

        &:hover svg {
            transform: translateX(rem(-10));
        }
    }

    .page {
        h3 {
            color: $color-grey-900;
            font-size: rem(18);
            @include text(400);
            margin: 0 0 rem(6) 0;
        }

        .path, .path a {
            color: $color-grey-700;
            font-size: rem(12);
            line-height: 1;
            text-decoration: none;
            transition: color 100ms linear;

            svg {
                font-size: rem(6);
                margin: 0 rem(4);
                position: relative;
                top: -1px;
            }
        }

        .path a:hover {
            color: $color-primary-500;
        }
    }
}
