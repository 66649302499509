@import '../../config';

.base {
    background-color: #ffffff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .08);
    border-radius: $border-radius;
    margin-bottom: rem(32);
    position: relative;
    padding: rem(32);

    .header {
        @include center-y;
        justify-content: space-between;

        h2 {
            margin: 0;
        }

        h2 + * {
            margin-left: rem(16);
        }

        & + * {
            margin-top: rem(24);
        }

        button, a {
            margin-top: -6px;
        }
    }

    table {
        margin-left: rem(-32);
        margin-right: rem(-32);
        width: calc(100% + rem(64));

        &:last-child {
            margin-bottom: rem(-32);

            tr:last-child td {
                border-bottom: 0;
            }
        }
    }

    &.isLoading {
        &:before {
            background-color: rgba(255, 255, 255, .7);
            bottom: 0;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 100;
        }

        .loader {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 101;

            &:before, &:after {
                border: 3px solid $color-primary-700;
            }
        }
    }
}
