@import '../../config';

.base {
    border: solid 1px $color-grey-200;
    border-collapse: separate;
    border-radius: 4px;
    border-spacing: 0;
    padding: 0;
    width: 100%;

    tr {
        td, th {
            color: $color-grey-900;
            border-bottom: solid 1px $color-grey-200;
            padding: .75rem 1rem;
            position: relative;
            transition: background 150ms linear;
            @include media-breakpoint-down(sm) {
                &:not(.notResponsive) {
                    padding-left: 1rem !important;
                }
            }

            &:first-child {
                padding-left: 1.5rem;
                @include media-breakpoint-down(sm) {
                    &:not(.notResponsive) {
                        padding-left: 1rem !important;
                    }
                }
            }
            &:last-child {
                padding-right: 1rem;
                @include media-breakpoint-down(sm) {
                    &:not(.notResponsive) {
                        padding-right: 1rem !important;
                    }
                }
            }

            &.collapsing {
                @include media-breakpoint-up(sm) {
                    white-space: nowrap;
                    width: 1px;
                }
            }

            &.centerAlign { text-align: center; }
            &.leftAlign { text-align: left; }
            &.rightAlign { text-align: right; }

            .collapseIcon {
                align-items: center;
                color: #b0b0b0;
                display: flex;
                justify-content: center;
                height: 2rem;
                margin: 1.125rem 0 0 1rem;
                transition: color 150ms linear, transform 150ms linear;
                width: 2rem;
                @include media-breakpoint-up(sm) {
                    display: none;
                }
            }
        }

        th {
            background: #ffffff;
            @include text(600);
            color: $color-grey-900;
            padding-bottom: .5rem;
            padding-top: .5rem;
            position: sticky;
            top: -2rem;
            z-index: 100;
            @include media-breakpoint-down(sm) {
                &:not(.notResponsive) {
                    display: none;
                }
            }
        }

        td:not(.notResponsive) {
            @include media-breakpoint-down(sm) {
                border-bottom: 0;
                display: none;
                height: auto;

                &.primary {
                    display: flex !important;
                    border-bottom: solid 1px #e5e5e5;
                    justify-content: space-between;
                }

                > div:first-child:before {
                    color: rgba(0, 0, 0, .6);
                    content: attr(data-name);
                    display: block;
                    font-size: .75rem;
                }

                &:last-child {
                    border-bottom: solid 1px #e5e5e5;
                }

                &.actions {
                    > div {
                        position: absolute;
                        top: 1rem;
                        right: 2.5rem;
                    }
                }
            }
        }

        &.expanded {
            @include media-breakpoint-down(sm) {
                display: block;
                position: relative;

                td {
                    display: block;
                    border-bottom: 0;

                    &:last-child {
                        border-bottom: solid 1px #d1d1d1;
                    }

                    .collapseIcon {
                        color: #000000;
                        transform: rotate(90deg);
                    }
                }
            }
        }

        &:first-child th {
            border-top: 0;

            &:first-child {
                border-top-left-radius: 4px;
            }
            &:last-child {
                border-top-right-radius: 4px;
            }
        }

        &:last-child td {
            border-bottom: 0;
        }

        &.error {
            td, th {
                background-color: rgba(222, 0, 0, .2);
                color: #dd0000;

                a {
                    color: #dd0000;
                    text-decoration: underline;
                }
            }
        }
    }

    tbody tr:hover {
        box-shadow: 0 0 10px -4px rgba(54, 74, 99, 0.2);
        
        td {
            background-color: #f8f9fc;
        }
    }

    &.fluid {
        border: 0;
        margin: 0 -2rem -2rem -2rem;
        width: calc(100% + 4rem);
    }
}

.actionsContainer {
    align-items: center;
    border-bottom: solid 1px $color-grey-200;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
    margin: -2rem -2rem 0 -2rem;

    .searchContainer {
        flex: 1;
    }

    .searchContainer + .filterContainer {
        border-left: solid 1px #e5e9f2;
        padding-left: 1rem;
    }

    .filterContainer {
        margin-left: rem(16);
    }
}

.pagination {
    display: flex;

    a {
        align-items: center;
        background: #ffffff;
        border: solid 1px $color-grey-200;
        border-right: 0;
        display: inline-flex;
        font-size: .8125rem;
        justify-content: center;
        line-height: 1rem;
        min-width: calc(1rem + 1.125rem + 2px);
        padding: .5625rem .625rem;

        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-right: solid 1px $color-grey-200;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &.isDisabled {
            color: $color-grey-500;
            cursor: default;
        }

        &.isActive {
            color: $color-primary-700;
        }
    }
}

.base tr td:hover {
    z-index: 1000;

    :global(.tooltip) {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}
