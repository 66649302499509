@import '../../config';

.base {
    position: relative;
    
    input {
        background-color: #ffffff;
        border: solid 1px $color-grey-300;
        border-radius: 4px;
        color: $color-grey-900;
        display: block;
        @include text(300);
        font-size: rem(16);
        line-height: rem(16);
        height: rem(42);
        outline: none;
        padding: 0 rem(16);
        transition: border-color 150ms linear;
        width: 100%;
    
        &::placeholder {
            color: $color-grey-300;
        }
    
        &:focus {
            border-color: $color-primary-500;
            box-shadow: 0 0 0 3px rgba(0, 56, 131, .1);
        }
    }

    .icon {
        @include center;
        background-color: $color-grey-200;
        border: solid 1px $color-grey-300;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
        height: rem(42);
        position: absolute;
        width: rem(42);

        svg {
            color: $color-grey-700;
            font-size: rem(16);
            transition: color 150ms linear;
        }
    }

    &.hasIcon input {
        padding-left: rem(50);
    }

    &.focus .icon {
        border-color: $color-primary-700;
        border-right-color: $color-grey-300;
        transition: border-color 150ms linear;
        
        svg {
            color: $color-primary-700;
        }
    }

    &.hasError {
        .icon {
            background-color: transparent;
            border-color: #dd0000;
            border-right: 0;
            width: rem(40);
        }

        svg {
            color: #dd0000;
        }

        input {
            border-color: #dd0000;
        }
    }

    &.isLoading {
        position: relative;
        > div:last-child {
            height: rem(32);
            position: absolute;
            right: rem(10);
            top: rem(5);
            width: rem(32);
        }
    }
}
