@import '../../kit/config';

.base {
    @include center-y;
    background-color: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, .05);
    height: rem(60);
    justify-content: space-between;
    padding: 0 0 0 rem(16);
    position: fixed;
    width: 100%;
    z-index: 100;

    .profile {
        @include center-y;
        text-decoration: none;
        @include media-breakpoint-down(md) {
            display: none;
        }

        .meta {
            margin-right: rem(16);
            line-height: 1;
            
            a {
                color: $color-grey-600;
                display: block;
                font-size: rem(12);
                margin-top: rem(4);
                text-align: right;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    > div {
        @include center-y;
    }

    .logout {
        @include center;
        background-color: transparent;
        border-left: solid 1px $color-grey-200;
        cursor: pointer;
        height: rem(60);
        font-size: rem(14);
        margin-left: rem(16);
        transition: background-color 150ms linear;
        width: rem(60);

        &:hover {
            background-color: $color-grey-100;
        }
    }
}

.push {
    height: rem(60);
    margin-bottom: rem(32);
}
