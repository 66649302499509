@import '../../config';

.base {
    display: flex;
    margin-bottom: rem(32);

    h1 {
        margin: 0;
    }

    > div:first-child {
        flex: 1;
        margin-right: 1rem;
    }
}
