@import './kit/config';

.main {
    padding-left: rem(60);
    @include media-breakpoint-down(md) {
        padding-left: 0;
    }
    &.notAuthenticated {
        padding-left: 0;
    }

    a:link, a:active, a:visited {
        color: $color-primary-700;
        text-decoration: none;

        &:hover {
            color: $color-grey-900;
            text-decoration: underline;
        }
    }
}
