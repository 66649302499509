@import '../../kit/config';

.base {
    align-items: center;
    background-color: $color-grey-200;
    border-radius: $border-radius;
    color: $color-grey-900;
    display: inline-flex;
    font-size: rem(12);
    font-weight: 500;
    line-height: 1;
    height: 44px;
    justify-content: center;
    position: relative;
    width: 44px;

    .image {
        background-position: top center;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: $border-radius;
        height: 100%;
        position: absolute;
        width: 100%;
    }
}
