$border-radius: 6px;

$color-grey-900: #878787;
$color-grey-700: #979ea6;
$color-grey-600: #acb4bd;
$color-grey-500: #bec5cb;
$color-grey-400: #cfd3d8;
$color-grey-300: #dee1e5;
$color-grey-200: #eceef0;
$color-grey-100: #f9f9fA;
$color-primary-700: #4eb9a9;
$color-primary-500: #c8e5e4;
$color-primary-300: #98e2d7;
$color-dark-700: #575756;
$color-dark-500: #2a3737;
$color-secondary-700: #ea6d20;
$color-secondary-500: #f8bc79;
$color-secondary-300: #fad3a8;
$color-error: #bc0000;
$color-success: #39a62d;
$color-warning: #ee9502;

$font-size: 16px;
