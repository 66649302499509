@import 'config';
@import 'sass/fonts';
@import 'sass/bootstrap';
@import 'sass/typography';

html {
    font-size: $font-size;
}

body {
    background-color: $color-grey-100;
    color: $color-grey-900;
    @include text();
    line-height: 1.5;
}

.truncate {
    @include truncate;
}

.success {
    color: $color-success;
}

.error {
    color: $color-error;
}

.primary {
    color: $color-primary-700;
}

.warning {
    color: $color-warning;
}

.loader {
    @include center;
    background-color: rgba(255, 255, 255, .7);
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: 9999;
}

// --- prices
.prices {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    > div {
        flex-basis: 33.33334%;
        @include media-breakpoint-down(md) {
            flex-basis: 100%;
        }
    }
}

.price-block {
    background-color: #ffffff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .08);
    border-radius: $border-radius;
    height: calc(100% - 32px);
    margin-bottom: rem(32);
    margin-left: rem(16);
    margin-right: rem(16);
    position: relative;
    padding: rem(32);
    text-align: center;

    h3 {
        font-size: rem(32);
    }

    &__label {
        background-color: $color-primary-700;
        display: block;
        color: #ffffff;
        margin: 0 rem(-32) rem(16) rem(-32);
        padding: rem(8) rem(16);
    }

    p, ul {
        margin: 0;
        @include media-breakpoint-down(md) {
            font-size: rem(14);
        }
    }

    ul {
        list-style-type: none;
        margin: 0 0 rem(16) 0;
        padding: 0;

        li {
            font-size: rem(15);
            padding: rem(8) 0;
            text-align: left;

            > svg {
                color: $color-primary-700;
                font-size: rem(16);
                margin-right: rem(6);
                position: relative;
                top: 1px;
            }
        }
    }

    .price-check {
        padding-top: rem(12);
        border-top: solid 1px #e5e5e5;

        > div {
            display: flex;

            label {
                font-size: 14px;
            }
        }
    }
}

.link-block__label {
    background-color: $color-primary-700;
    display: block;
    color: #ffffff;
    margin: 0 rem(-32) rem(16) rem(-32);
    padding: rem(8) rem(16);
}
