@import '../../config';

.base {
    align-items: center;
    border: 0;
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    font-size: rem(14);
    @include text(400);
    line-height: 1;
    padding: rem(10) rem(20);
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 150ms linear;
    vertical-align: middle;

    &:hover {
        text-decoration: none !important;
    }

    svg {
        font-size: 17px;
    }

    &.disabled {
        cursor: default !important;
        opacity: .5;
    }

    &.iconOnly {
        align-items: center;
        font-size: rem(16);
        border-radius: 10px;
        height: rem(32);
        padding: 0;
        justify-content: center;
        width: rem(32);
    }

    &.border {
        background-color: #ffffff;
        border: solid 1px $color-grey-300;
        border-radius: 3px;
        color: $color-grey-900 !important;
        &:hover {
            background-color: #f0f0f0;
        }
    }

    &.primary {
        background-color: $color-primary-700;
        color: #ffffff !important;
        &:hover {
            background-color: $color-primary-500;
        }
    }

    &.white {
        background-color: #ffffff;
        color: $color-grey-900 !important;
        &:hover {
            background-color: #f0f0f0;
        }
    }

    &.secondary {
        background-color: $color-secondary-700;
        color: #ffffff;
        &:hover {
            background-color: $color-primary-700;
            color: #ffffff;
        }
        &.disabled {
            color: #ffffff !important
        }
    }

    &.transparent {
        background-color: transparent;
        color: $color-primary-700;
        &:hover {
            color: $color-primary-300;
            text-decoration: underline !important;
        }
        &.disabled {
            color: $color-primary-300 !important;
        }
    }

    svg:first-child + span {
        margin-left: rem(12);
    }
    span + svg:last-child {
        margin-left: rem(12);
    }

    &.iconRight {
        svg {
            order: 2;
        }
        
        svg:first-child + span {
            margin-right: rem(12);
        }
    }

    &.block {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.base + .base:not(.link) {
    margin-left: rem(8);
}

.tooltip {
    background-color: $color-grey-900;
    border-radius: 3px;
    color: #ffffff;
    font-size: 12px;
    left: 50%;
    max-width: 300px;
    padding: rem(8) rem(16);
    pointer-events: none;
    position: absolute;
    opacity: 0;
    text-transform: none;
    top: rem(-20);
    transform: translateX(-50%);
    transition: all 150ms ease-out;
    white-space: nowrap;
    z-index: 2;
    
    &:after {
        border: solid rem(4) transparent;
        border-color: rgba(0, 0, 0, 0);
        border-top-color: $color-grey-900;
        content: '';
        height: 0;
        margin-left: rem(-4);
        left: 50%;
        position: absolute;
        pointer-events: none;
        top: 100%;
        width: 0;
    }
}

.base:hover .tooltip {
    opacity: 1;
    top: rem(-36);
}
