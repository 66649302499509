@import '../../config';

.base {
    @include center-y;
    box-shadow: 0 3px .75rem 1px rgba(0, 0, 0, .14);
    background: #ffffff;
    border: solid 1px transparent;
    border-radius: $border-radius;
    margin-bottom: rem(28);
    padding: rem(8) rem(16);

    > div {
        flex: 1;
        padding-right: rem(16);

        h6 {
            margin-bottom: 1px;
        }
    }

    p {
        font-size: rem(14);
        margin: 0;
    }

    button {
        color: $color-grey-900 !important;
    }

    > svg:first-child {
        margin-right: rem(16);
        font-size: rem(20);
    }

    &.error {
        background-color: #fdece9;
        border-color: $color-error;
        > svg:first-child { color: $color-error; }
    }
    &.warning {
        background-color: #fef8ea;
        border-color: $color-warning;
        > svg:first-child { color: $color-warning; }
    }
    &.success {
        background-color: #ebf8ee;
        border-color: $color-success;
        > svg:first-child { color: $color-success; }
    }

    &.fixed {
        bottom: 0;
        right: 1rem;
        position: fixed;
    }
}

.base + .base {
    margin-top: -1.25rem;
}
