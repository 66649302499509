@import '../../kit/config';

.base {
    background-color: $color-dark-700;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 200;
    @include media-breakpoint-down(md) {
        width: rem(60);
        height: rem(60);
    }

    .top {
        @include center;
        height: rem(60);
        margin-bottom: rem(26);

        img {
            display: block;
            width: rem(32);
        }
    }
}

.navigation {
    padding: 0 rem(8);
    @include media-breakpoint-down(md) {
        background-color: $color-dark-700;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: rem(60);
        @include center;
        justify-content: space-between;
        align-items: flex-end;
    }

    > a {
        @include center;
        background-color: transparent;
        border-radius: $border-radius;
        height: rem(44);
        font-size: rem(19);
        margin-bottom: rem(8);
        position: relative;
        transition: background-color 150ms linear;
        width: rem(44);

        &:hover, &.isActive {
            background-color: $color-primary-700;
        }

        &:hover {
            :global(.tooltip) {
                opacity: 1;
                transform: translateX(16px);
            }
        }
    }

    .divider {
        background-color: rgba(255, 255, 255, .15);
        margin: rem(8) 0;
        height: 1px;
        width: 100;
    }
}
