@mixin center {
    align-items: center;
    display: flex;
    justify-content: center;
}

@mixin center-x {
    display: flex;
    justify-content: center;
}

@mixin center-y {
    align-items: center;
    display: flex;
}

@mixin map-spacing($property: padding) {
    &--none { #{$property}: 0; }
    &--dense { #{$property}: rem(8); }
    &--compact { #{$property}: rem(16); }
    &--normal { #{$property}: rem(32); }
    &--padded { #{$property}: rem(48); }
}

@mixin styled-scrollbar($color: #e8f6f6, $width: 12px) {
    scrollbar-color: $color transparent;
    scrollbar-width: thin;
    
    &::-webkit-scrollbar {
        width: $width;
    }
      
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
      
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: solid 3px transparent;
        background-clip: content-box;
        background-color: $color;
    }
}

@mixin text($weight: 300, $italic: false) {
    font-family: 'Kanit', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: $weight;
    @if $italic {
        font-style: italic;
    }
}

@mixin truncate {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
