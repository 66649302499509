@import '../../kit/config';

.tooltip {
    background-color: $color-dark-700;
    border-radius: 4px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .05);
    color: #ffffff;
    font-size: rem(12);
    padding: 8px 16px;
    pointer-events: none;
    position: absolute;
    opacity: 0;
    text-transform: none;
    transition: all 150ms ease-out;
	white-space: nowrap;
    z-index: 300;
    
    &:after {
        border: solid 5px transparent;
        border-color: rgba(0, 0, 0, 0);
        content: '';
        height: 0;
        position: absolute;
        pointer-events: none;
        width: 0;
    }

    &.left {
        left: 100%;
        transform: translateX(-16px);

        &:after {
            border-right-color: $color-dark-700;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &.top {
        left: 50%;
        top: rem(-56);
        transform: translate(-50%, 16px);

        &:after {
            border-top-color: $color-dark-700;
            left: 50%;
            top: 100%;
            transform: translateX(-50%);
        }
    }

    &.bottom {
        left: 50%;
        bottom: 100%;
        transform: translate(-50%, 16px);

        &:after {
            border-top-color: $color-dark-700;
            left: 50%;
            top: 100%;
            transform: translateX(-50%);
        }
    }
}
