@import '../../config.scss';

.group > label:first-child, .label {
    color: $color-grey-900;
    display: block;
    font-size: rem(14);
    @include text(400);
    line-height: rem(14);
    margin-bottom: rem(8);
    
    > span {
        color: #dd0000;
        font-size: rem(14);
    }
}

.group {
    margin-bottom: rem(16);

    &.hasError {
        .label {
            color: #dd0000;
        }
    }

    .info {
        display: block;
        font-size: rem(14);
        margin-bottom: 0;
        margin-top: rem(6);
    }

    &.hasError .info {
        color: #dd0000;
    }

    &.money {
        input {
            text-align: right;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}
