@import '../../config';

form .row + .row {
    margin-top: rem(16);
}

form .row:last-of-type {
    margin-bottom: rem(16);
}

:global(.col-lg-6 + .col-lg-6) {
    @include media-breakpoint-down(lg) {
        margin-top: rem(32);
    }
}
