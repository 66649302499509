@import '../config';

h1, h2, h3, h4, h5, h6 {
    @include text(600);
    color: $color-dark-700;
    margin: 0;
}

h1 {
    font-size: rem(32);
    line-height: rem(32);
    margin-bottom: rem(32);
}

h2 {
    font-size: rem(24);
    line-height: rem(24);
    margin-bottom: rem(24);
}

h3 {
    font-size: rem(20);
    line-height: rem(20);
    margin-bottom: rem(20);
}

h4 {
    font-size: rem(18);
    line-height: rem(18);
    margin-bottom: rem(18);
}

h5 {
    font-size: rem(16);
    line-height: rem(16);
    margin-bottom: rem(16);
}

h6 {
    font-size: rem(14);
    line-height: rem(14);
    margin-bottom: rem(14);
}
